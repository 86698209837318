<template>
  <div class="container">
    <Header />
    <div class="choose">
      <div class="box" v-for="item in columnList" :key="item.id">
        <div class="choose-left">
          <div @click="handleClickEnterInto(item)">
            {{h5Language=='En'?item.name:item.nameEn}}
          </div>
          <img @click="hanleClickChild(item)" v-show="item.children&&item.children.length>0&&!item.flag" class="choose-sum" src="../assets/home/jiahao1@2x.png">
          <img @click="hanleClickChild(item)" v-show="item.children&&item.children.length>0&&item.flag" class="choose-minus" src="../assets/home/line.png">
        </div>
        <img class="line2" src="../assets/home/line2@2x.png" alt="">
        <div v-if="item.children&&item.children.length>0&&item.flag" style="margin-left: 20px;overflow: hidden" class="child-box" :class="item.flag?'unfold_top':'unfold_bottom'">
          <div @click="handleChangeClickToChild(item,child)" v-for="(child,i) in item.children" :key="child.id"  class="child" :class="i==0?'pd_24':''">
            {{h5Language=='En'?child.name:child.nameEn}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/header'
import { cloumnTree } from '../api'

export default {
  components: {
    Header
  },
  name: 'slide',
  data () {
    return {
      columnList: [],
      h5Language:''
    }
  },
  beforeRouteEnter (to, from, next){
    next(vm=>{          //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to,11)
      console.log(from,22)
      // if(from&&from.name){
      //   localStorage.setItem('routerName',from.name)
      // }else{
      //   localStorage.setItem('routerName','')
      // }
    })
  },
  mounted () {
    this.h5Language = localStorage.getItem('h5Language')||'En'
    this.cloumnTree()
  },
  methods: {
    handleClickEnterInto (item) {
      if(item.url=='home'){
        this.$router.push({path:'/'})
      }else{
        this.$router.push({ path: '/' + item.url + '?id=' + item.id })
      }
    },
    handleChangeClickToChild(item,child){
      if(item.url=='home'){
        this.$router.push({path:'/'})
      }
      this.$router.push({path:'/'+item.url,query:{listId:item.id,anchorId:child.id}})
    },
    hanleClickChild (item) {
      this.columnList.forEach(e => {
        if (item.id === e.id) {
          e.flag = !e.flag
        } else {
          e.flag = false
        }
      })
    },
    cloumnTree () {
      cloumnTree().then(res => {
        if (res.code === 200) {
          res.data.forEach(e => {
            e.flag = false
          })
          this.columnList = res.data
        }
        console.log(res, '我是返回的res')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  position: relative;
  .choose{
    position: absolute;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    background: #002E73;
    padding: 28px 20px;
    z-index: 999;
    .box{
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      .line2{
        position: absolute;
        top: 46px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 2px;
      }
    }

    .choose-left{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 22px;
      position: relative;
    }
    .choose-minus{
      height: 1px;
      width: 18px;
      margin-right: 21px;
    }
    .choose-sum{
      width: 18px;
      height: 18px;
      margin-right: 21px;
    }
    .child{
      width: 315px;
      padding-top: 12px;
      padding-bottom: 12px;
      //height: 44px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      border-bottom: 1px #8494AD solid;
    }
    .pd_24{
      margin-top: 12px;
    }
    .scleTop {
      animation: fold_top 1s forwards ease-in-out;
    }
    .scleBottom {
      animation: fold_bottom 1s forwards ease-in-out;
    }
    .unfold_top {
      animation: unfold_top 0.8s ease-in-out;
    }
    .unfold_bottom {
      animation: unfold_bottom 0.8s ease-in-out;
    }
    @keyframes fold_top {
      100% {
        transform-origin: top;
        transform: perspective(100px) rotateX(-10deg);
        height: 0;
      }
    }
    @keyframes fold_bottom {
      100% {
        transform-origin: bottom;
        transform: perspective(100px) rotateX(10deg);
        height: 0;
      }
    }
    @keyframes unfold_top {
      0% {
        transform-origin: top;
        height: 0;
        transform: perspective(100px) rotateX(-10deg);
      }
      100% {
        transform: perspective(100px) rotateX(0deg);
        height: 160px;
      }
    }
    @keyframes unfold_bottom {
      0% {
        transform-origin: bottom;
        height: 0;
        transform: perspective(100px) rotateX(10deg);
      }
      100% {
        transform: perspective(100px) rotateX(0deg);
        height: 80px;
      }
    }
  }
}

</style>
